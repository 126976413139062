/* RecruitersPage.css */

/* Container Styles */
.rcontainer {
    background-color: #F2F2F2;
    /* height: 100vh; */
    padding: 30px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .header div {
    margin-bottom: 5px;
  }
  
  .header-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }
  
  .header-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
  }
  
  /* Buttons Styles */
  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .button-group button {
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-group .primary-button {
    width: 183px;
    height: 40px;
    background-color: #00ADF0;
    color: #FAFAFA;
    border: 1px solid #00ADF0;
    cursor: pointer;
  }
  
  .button-group .secondary-button {
    width: 112px;
    height: 40px;
    color: #00ADF0;
    border: 1px solid #00ADF0;
    cursor: pointer;
  }
  
  /* Stats Cards Styles */
  .stats-cards {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 20px;
    padding-top: 20px;
  }
  
  .stats-card {
    background-color: #FFFFFF;
    width: 100%;
    max-width: 300px;
    height: 70px;
    border: 1px solid #DAE1E7;
    border-radius: 4px;
    display: flex;
    padding: 12px 16px;
    gap: 16px;
    /* align-items: center; */
  }
  
  .stats-card img {
    width: 26px;
    height: 26px;
  }
  
  .stats-right-content {
    padding-left: 10px;
  }

  /* .stats-card div {
    flex-grow: 1;
  } */
  
  .stats-card .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #726C6C;
  }
  
  .stats-card .value {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: #2B2829;
    padding-top: 10px;
  }
  
  /* Filter Section Styles */
  .filter-section {
    background-color: #FFFFFF;
    margin-top: 25px;
    padding: 16px 24px;
  }
  
  .filter-section .filter-bar {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 10px;
  }

  .filter-group {
    display: flex !important;
    flex: 3;
    height: auto;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .filter-bar .filter-input {
    width: 404px;
    height: 20px;
    padding: 10px 14px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #CED7DE;
  }

  .search-input {
    width: 404px;
    height: 40px;
  }
  
  .filter-bar .filter-select {
    width: 150px;
    height: 40px;
    /* padding: 10px 14px; */
    gap: 8px;
    border-radius: 4px;
    /* border: 1px solid #CED7DE; */
    color: #7991A4;
  }
  
  .filter-bar .filter-button {
    width: 125px;
    height: 40px;
    padding: 0 24px;
    border-radius: 4px;
    background-color: #DAE1E7;
    border: none;
    cursor: pointer;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .filter-bar .filter-button img {
    margin-right: 8px;
  }

  .filter-bar .filter-button div {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  
  /* Table Styles */
  .table-container {
    padding-top: 20px;
  }
  
  .ant-modal-content-modify .ant-modal-content {
    width: 486px !important;
    height: 1000px !important;
    position: fixed !important;
    top: -7px !important;
    right: -7px !important;
    background-color: #FFFFFF !important;
    padding: 0 !important;
    padding-top: 20px !important;
    margin-bottom: 15px !important;
    /* padding-left: 15px !important; */
  }

  .table-active-button {
    border-radius: 16px;
    padding: 2px 8px;
    width: 53px;
    height: 18px;
    background-color: #ECFDF3;
    color: #027A48;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
  }

  .create-modal-cancel-button {
    width: 86px;
    height: 35px; 
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    opacity: 1;
    background-color: #DAE1E7;
    color: #2B2829;
    border: none;
  }

  .recruiter-create-input-div {
    width: 344px;
  }

  .recruiter-create-input-div label {
    font-size: 14px;
    font-weight: 500;
    color: #323F49;
    line-height: 17px;
  }

  .recruiter-create-input {
    border-radius: 4px;
    border: 1px solid #CED7DE;
    padding: 10px 14px;
  }

  .create-modal-confirm-button {
    width: 86px;
    height: 35px; 
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    opacity: 1;
    background-color: #00ADF0;
    color: #fff;
    border: none;
  }

  .active-lable {
    background-color: #ECFDF3;
    color: #027A48;
    height: 21px;
    padding: 2px 8px 2px 8px;
    gap: 0px;
    border-radius: 16px 0px 0px 0px;
    cursor: pointer;
  }

  .inactive-lable {
    background-color: #FEF3F2;
    color: #B42318;
    height: 21px;
    padding: 2px 8px 2px 8px;
    gap: 0px;
    border-radius: 16px 0px 0px 0px;
    cursor: pointer;
  }

  .recruiter-create-raw {
    display: flex;
    flex-wrap: wrap;
    gap: 16px
  }

  .recruiter-edit-button {
    color: #00ADF0;
    background-color: #FFFFFF;
    border: 1px solid #00ADF0;
    border-radius: 4px;
    width: 55px;
    height: 28px;
    cursor: pointer;
  }

  .profile-name-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .profile-icon {
    display: flex;
    align-items: center;
  }

  .profile-content-div {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    padding-top: 8px;
  }

  .profile-content-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .profile-footer {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .recruiter-reset-password-button {
    width: 214px;
    height: 40px; 
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    opacity: 1;
    background-color: #DAE1E7;
    color: #2B2829;
    border: none;
  }

  .recruiter-delete-button {
    width: 214px;
    height: 40px; 
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    opacity: 1;
    background-color: #FFFFFF;
    color: #D92D20;
    border: 1px solid #D92D20;
  }

  .profile-main-details {
    height: 155px;
    border: 1px solid #DAE1E7;
    background-color: #F2F7F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    gap: 12px;
  }

  .profile-details-div {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding-left: 20px;
    padding-top: 20px;
    gap: 12px;
  }

  .details-role {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    /* line-height: 17px; */
    /* padding-left: 20px; */
    /* padding-top: 20px; */
  }

  .details-role-name {
    font-weight: 400;
    margin-bottom: 10px;
    /* padding-left: 20px; */
    /* padding-top: 5px; */
  }

  .ant-modal-content-modify .ant-modal-header {
    margin-bottom: 18px !important;
  }


  @media (max-width:767px) {
  
    .stats-cards{
      flex-wrap: wrap;
    }

    .search-input {
      width: 320px;
    }
 }